<template>

<div class="tick">

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.tick {
	position: absolute;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: #23D6F7;
	background-image: url(~@/assets/tick.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.is-mobile .tick {
	width: 32px;
	height: 32px;
	background-size: 16px 16px;
}

</style>
