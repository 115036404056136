<template>

<div class="icon">

	<i :class="{[clsIcon]: true}"></i>

</div>

</template>

<script>

export default {

	props: ['icon'],

	data: function() {

		return {
			shortcut: {
				loading: 'fas fa-spinner fa-spin'
			}
		}

	},

	computed: {

		clsIcon: function() {

			return this.shortcut[this.icon]

		}

	}

}

</script>

<style scoped>

</style>
