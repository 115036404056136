<template>

<div class="page" :class="{'is-centralise': centralise, 'is-titled': title || closeButton, 'is-back': backButton, [clsTheme]: true}">

	<router-link :to="{name: closeButton, params: closeButtonMeta}" v-if="closeButton" class="page-close"></router-link>
	<router-link :to="{name: backButton}" v-if="backButton" class="page-back">Back</router-link>

	<div v-if="title" class="page-title">{{ title }}<div class="page-title-label" v-if="label">{{ label }}</div></div>

	<div class="page-menu" :class="{'is-expanded': isExpand}" v-if="$route.meta.hasMenu">

		<div class="page-menu-inner">
			<div class="page-menu-toggle" v-if="window.is.mobile" v-on:click="isExpand = !isExpand"><span /><span /><span /></div>
			<template v-if="!window.is.mobile || isExpand">
			<router-link :to="{ name: 'Home'}" class="page-menu-item" v-if="$route.name !== 'Home'">Home</router-link>
			<router-link :to="{ name: 'Generic', params: { slug: link.slug }}" class="page-menu-item" v-for="(link, index) in links" :key="index">{{ link.title }}</router-link>
			</template>
		</div>

	</div>

	<div class="page-content" :class="{'is-sized': sizeContent, 'no-padding': noPadding}" v-if="!loading">
		
		<slot></slot>

	</div>

	<app-icon icon="loading" class="page-loading" v-if="loading" />

</div>

</template>

<script>

export default {

	props: ['centralise', 'loading', 'noPadding', 'closeButtonMeta', 'theme', 'title', 'label', 'backButton', 'closeButton', 'sizeContent'],

	data: function() {

		return {
			isExpand: false
		}

	},

	watch: {

		url: function() {

			this.isExpand = false

		}

	},

	computed: {

		url: function() {

			return this.$route.path

		},

		links: function() {

			return this.$store.getters['data'].links

		},

		clsTheme: function() {

			return 'theme-' + this.theme

		}

	}

}

</script>

<style scoped>

.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	min-height: calc(100vh - var(--vh-offset, 0px));
}

.page-menu {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	height: 40px;
	background-color: #2A273E;
	z-index: 2;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.is-mobile .page-menu.is-expanded {
	bottom: 0px;
	height: auto;
}

.page-menu-toggle {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.page-menu-toggle span {
	display: block;
	width: 24px;
	height: 2px;
	background-color: #fff;
	margin: 3px 0px;
	border-radius: 4px;
}

.page-menu-inner {
	max-width: 1120px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.is-mobile .page-menu-inner {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
}

.page-menu-item {
	color: #fff;
	font-size: 14px;
	font-weight: 300;
	padding: 0px 6px;
	line-height: 40px;
}

.page-menu-item.router-link-exact-active {
	font-weight: 700;
}

.page-menu-item:hover {
	text-decoration: underline;
}

.page-content {
	width: 100%;
}

.page-content.is-sized {
	max-width: 1160px;
	padding: 0px 20px;
}

.is-mobile .page-content.is-sized {
	padding: 0px 10px;
}

.page-title {
	position: absolute;
	left: 20px;
	top: 10px;
	display: flex;
	height: 60px;
	align-items: center;
	color: #fff;
	font-size: 24px;
	font-weight: 300;
	letter-spacing: 2.4px;
	text-transform: uppercase;
	max-width: calc(100% - 80px);
}

.is-mobile .page-title {
	left: 10px;
	font-size: 18px;
	height: 40px;
	margin-left: 0px;
}

.page-title-label {
	display: inline-block;
	background-color: #58C145;
	border-radius: 15px;
	color: #2A273E;
	font-size: 14px;
	line-height: 22px;
	padding: 0px 10px;
	font-weight: 400;
	margin-left: 10px;
}

.page.is-back {
	padding-top: 120px;
}

.is-mobile .page.is-back {
	padding-top: 40px;
}

.page.is-titled {
	padding-top: 120px;
	padding-bottom: 60px;
}

.is-mobile .page.is-titled {
	padding-top: 80px;
	padding-bottom: 20px;
}

.page.theme-purple {
	background-color: #2A273E;
}

.page.theme-white {
	background-color: #fff;
}

.page.is-centralise {
	justify-content: center;
	flex-direction: row;
	align-items: center;
}

.is-mobile .page.page.is-centralise {
	align-items: flex-start;
	padding-top: 20px;
}

.page.is-centralise .page-content {
	width: auto;
}

.page-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
}

.page-close {
	position: absolute;
	right: 20px;
	top: 10px;
	width: 60px;
	height: 60px;
	background-image: url(~@/assets/close.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.page-back {
	position: absolute;
	left: 20px;
	top: 10px;
	width: 60px;
	height: 60px;
	background-image: url(~@/assets/back.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	font-size: 18px;
	letter-spacing: 1.8px;
	color: #2A273E;
	text-transform: uppercase;
	line-height: 60px;
	padding-left: 60px;
}

.is-mobile .page-close {
	right: 5px;
	left: auto;
	height: 40px;
	background-size: 16px 16px;
	width: 40px;
	margin-left: 0px;
}

.is-mobile .page-back {
	left: 0px;
	top: 0px;
	height: 40px;
	background-size: 16px 16px;
	width: 40px;
	font-size: 14px;
	padding-left: 40px;
	line-height: 40px;
	margin-left: 0px;
}

</style>
