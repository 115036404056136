<template>

<div class="button" :class="{'is-disabled': disabled, 'is-loading': loading, [clsTheme]: true}" v-on:click="$emit('click')">

	<div class="button-text">{{ localText }}</div>

	<app-icon icon="loading" class="button-loading" v-if="loading" />

</div>

</template>

<script>

export default {

	props: ['text', 'disabled', 'loading', 'theme', 'status'],

	computed: {

		localText: function() {

			return this.text

		},

		clsTheme: function() {

			return 'theme-' + this.theme

		}

	}

}

</script>

<style scoped>

.button {
	display: block;
	margin: 0px auto;
	padding: 0px 20px;
	width: 200px;
	line-height: 40px;
	background-color: #4082d3;
	border-radius: 20px;
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 1px;
	transition: all 100ms linear;
}

.is-mobile .button {
	font-size: 18px;
	line-height: 22px;
	padding: 9px 20px;
}

.button.theme-red {
	background-color: #c55b5b;
}

.button.theme-green {
	background-color: #7bc758;
}

.button.theme-dark {
	background-color: #2A273E;
	color: #fff;
}

.button.theme-white {
	background-color: #fff;
	color: #343434;
	border: 2px solid #23CFFD;
}

.button.theme-purple {
	background-color: #2A273E;
	color: #fff;
	border: 2px solid #23D6F7;
}

.button.is-loading {
	pointer-events: none;
}

.button.is-loading .button-text {
	opacity: 0;
}

.button-loading {
	font-size: 16px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.button.is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.button:hover {
	background-color: #236dc9;
}

.button.theme-red:hover {
	background-color: #c94141;
}

.button.theme-green:hover {
	background-color: #6ab646;
}

.button.theme-dark:hover {
	background-color: #23D6F7;
}

.button.theme-purple:hover {
	background-color: #23D6F7;
}

.button.theme-white:hover {
	background-color: #23D6F7;
	color: #fff;
}

</style>
