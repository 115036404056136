<template>

<div class="title" :class="{'is-subtitle': isSubtitle, 'is-dark': isDark, 'is-restricted': isRestricted, 'is-uppercase': isUppercase, 'hide-line': hideLine}">

	<div class="title-text">{{ text }}</div>

</div>

</template>

<script>

export default {

	props: ['text', 'isSubtitle', 'isUppercase', 'isRestricted', 'isDark', 'hideLine']

}

</script>

<style scoped>

.title {
	color: #fff;
	font-size: 36px;
	letter-spacing: 3.6px;
	line-height: 36px;
	margin-bottom: 80px;
	text-align: center;
}

.is-mobile .title {
	font-size: 28px;
	letter-spacing: 2.8px;;
	line-height: 32px;
	margin-bottom: 40px;
}

.title.is-dark {
	color: #312D50;
}

.title.is-uppercase {
	text-transform: uppercase;
}

.title.is-subtitle {
	font-size: 18px;
	text-align: left;
	line-height: 22px;
	letter-spacing: 1.8px;
	font-weight: bold;
}

.is-mobile .title.is-subtitle {
	font-weight: 300;
	text-align: center;
}

.title.is-restricted {
	max-width: 640px;
	padding: 0px 10px;
	text-align: center;
	margin: 0px auto 80px auto;
}

.is-mobile .title.title.is-restricted {
	margin: 0px auto 60px auto;
}

.title:after {
	content: '';
	position: absolute;
	width: 149px;
	height: 4px;
	background-color: #34D6F5;
	bottom: -40px;
	left: 50%;
	width: 150px;
	margin-left: -75px;
}

.title.is-subtitle:after {
	left: 0px;
	width: 75px;
	bottom: -20px;
	margin-left: 0px;
}

.is-mobile .title.is-subtitle:after {
	left: 50%;
	width: 75px;
	bottom: -20px;
	margin-left: -38px;
}

.title.hide-line:after {
	display: none;
}

.is-mobile .title:after {
	bottom: -20px;
}

</style>
