export default {

	computed: {

	},

	methods: {

	}

}