<template>

<div class="screen" :class="{'is-loading': loading}">

	<app-icon icon="loading" v-if="loading" class="screen-loading" />

	<template v-if="!loading">

	<slot></slot>

	</template>

</div>

</template>

<script>

export default {

	props: ['loading']

}

</script>

<style>

.screen {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
}

.screen-loading {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 48px;
	color: #ffbe7a;
}

</style>