import axios from 'axios'
import pubsub from '@/pubsub'
import _ from 'underscore'

const CancelToken = axios.CancelToken

export default {

	api: 'https://inhalers.staging.exesios.com/api/', 
	//api: 'https://inhaler.ddev.site/api/',

	source: false,

	cancel: function() {

		this.source.cancel()

	},

	get: function(url, params, page) {

		params = params || {}
		page = page || false

		if (page) params['page'] = page

		this.source = CancelToken.source()

		return new Promise(function(resolve, reject) {

			axios.get(this.api + url + '.json', {
				cancelToken: this.source.token,
				params: params
			}).then(function(response) {

				this.response(resolve, response)

			}.bind(this), function() {

				reject()

			})

		}.bind(this))

	},

	post: function(url, params) {

		params = params || {}

		return new Promise(function(resolve, reject) {

			axios.post(this.api + url + '.json', params).then(function(response) {

				this.response(resolve, response)

			}.bind(this), function() {

				reject()

			})

		}.bind(this))

	},

	patch: function(url, params) {

		params = params || {}

		return new Promise(function(resolve, reject) {

			axios.patch(this.api + url + '.json', params).then(function(response) {

				this.response(resolve, response)

			}.bind(this), function() {

				reject()

			})

		}.bind(this))

	},

	delete: function(url) {

		return new Promise(function(resolve, reject) {

			axios.delete(this.api + url + '.json').then(function(response) {

				this.response(resolve, response)

			}.bind(this), function() {

				reject()

			})

		}.bind(this))

	},

	response: function(resolve, response) {

		var events = response.data.events

		_.each(events, function(event) {

			pubsub.$emit(event.name, event.params)

		})

		resolve(response.data.data)

	}

}