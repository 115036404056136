<template>

<div class="expand" :class="{'is-active': isActive && !isLocked, 'is-locked': isLocked}">

	<div class="expand-title" v-on:click="$emit('click')">{{ title }}</div>

	<div class="expand-content">

		<div v-html="text" />

		<slot></slot>

	</div>

</div>

</template>

<script>

export default {

	props: ['title', 'isActive', 'text', 'isLocked']

}

</script>

<style scoped>

.expand {
	width: 100%;
	border-bottom: 1px solid #707070;
}

.expand-title {
	background-image: url(~@/assets/chevron.down.svg);
	background-repeat: no-repeat;
	background-position: 100% 50%;
	padding: 20px 0px;
	color: #312D50;
	font-size: 24px;
	line-height: 29px;
	cursor: pointer;
	font-weight: 500;
}

.is-mobile .expand-title {
	padding: 20px 40px 20px 0px;
	background-position: 100% 30px;
}

.expand.is-locked .expand-title {
	background-image: none;
}

.expand.is-active .expand-title {
	background-image: url(~@/assets/chevron.up.svg);
}

.expand-content {
	display: none;
	padding-bottom: 40px;
}

.is-mobile .expand-content {
	padding-bottom: 20px;
}

.expand.is-active .expand-content,
.expand.is-locked .expand-content {
	display: block;
}

</style>
