import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [

		{ name: 'Home', path: '/', meta: { hasMenu: true }, component: () => import('@/components/page/Home') },
		{ name: 'Profile', path: '/profile/:key', meta: { hasMenu: true }, component: () => import('@/components/page/Home') },
		{ name: 'Questions', path: '/questions', meta: {}, component: () => import('@/components/page/Questions') },
		{ name: 'Questions.Info', path: '/questions/info', meta: {}, component: () => import('@/components/page/Questions.Info') },
		{ name: 'Recommendation', path: '/recommendation', meta: { hasMenu: true }, component: () => import('@/components/page/Recommendation') },
		{ name: 'Recommendation.Select', path: '/recommendation/select', meta: {}, component: () => import('@/components/page/Recommendation.Select') },
		{ name: 'Recommendation.Video', path: '/recommendation/video', meta: {}, component: () => import('@/components/page/Recommendation.Video') },
		{ name: 'Recommendation.Steps', path: '/recommendation/steps', meta: {}, component: () => import('@/components/page/Recommendation.Steps') },
		{ name: 'Recommendation.About', path: '/recommendation/about', meta: {}, component: () => import('@/components/page/Recommendation.About') },
		{ name: 'Recommendation.Survey', path: '/recommendation/survey', meta: {}, component: () => import('@/components/page/Recommendation.Survey') },
		{ name: 'Recommendation.Compare', path: '/recommendation/compare/:filter?', meta: {}, component: () => import('@/components/page/Recommendation.Compare') },
		{ name: 'Recommendation.Compare.More', path: '/recommendation/compare/:filter?/:more', meta: {}, component: () => import('@/components/page/More') },
		{ name: 'Generic', path: '/:slug', meta: { hasMenu: true }, component: () => import('@/components/page/Generic') }

	]
})