import Vue from 'vue'
import _ from 'underscore'
import api from '@/api'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

	modules: {},

	state: {
		data: {},
		session: {
			answers: {},
			survey: {},
			recommendation: 0,
			selection: 0,
			completed: {
				video: false,
				steps: false,
				about: false,
				compare: false,
				survey: false
			}
		}
	},

	getters: {

		data: function(state) {

			return state.data

		},

		answers: function(state) {

			return state.session.answers

		},

		survey: function(state) {

			return state.session.survey

		},

		completed: function(state) {

			return state.session.completed

		},
		
		recommendation: function(state) {
			
			return (state.session.recommendation) ? state.data.recommendations.recommendation[state.session.recommendation] : false

		},
		
		types: function(state) {
			
			return state.data.inhalers.types

		},
		
		selection: function(state) {
			
			return _.findWhere(state.data.inhalers.products, {
				id: state.session.selection
			})

		},
		
		'selection/type': function(state, getters) {
			
			return _.findWhere(state.data.inhalers.types, {
				id: getters['selection'].type
			})

		}

	},

	mutations: {

		recommendation: function(state, value) {

			state.session.recommendation = value

		},

		selection: function(state, value) {

			state.session.selection = value

		},

		completed: function(state, value) {

			state.session.completed[value] = true

		},

		answer: function(state, e) {

			Vue.set(state.session.answers, e.name, parseInt(e.value))

		},

		answers: function(state, value) {

			Vue.set(state.session, 'answers', value)

		},

		survey: function(state, e) {

			Vue.set(state.session.survey, e.name, e.value)

		}

	},

	actions: {

		recommend: function(context) {

			context.commit('recommendation', false)

			_.each(context.state.data.recommendations.recommendation, function(recommendation, index) {

				var met = 0

				_.each(recommendation.criteria, function(value, key) {

					if (value === context.state.session.answers[key]) met++

				})

				if (met === _.keys(recommendation.criteria).length) context.commit('recommendation', index)

			})

		},

		init: function(context, key) {

			return new Promise(function(resolve) {

				api.get('data', {
					key: key || false
				}).then(function(response) {

					Vue.set(context.state, 'data', response.data)

					if (response.session) {
						
						Vue.set(context.state, 'session', response.session.data)

					}

					resolve()

				})

			})

		},

		save: function(context, email) {

			return new Promise(function(resolve) {

				api.post('profile', {
					email: email,
					data: context.state.session
				}).then(function(data) {

					resolve(data)

				})

			})

		},

		verify: function(context, key) {

			return new Promise(function(resolve) {

				api.get('profile', {
					key: key
				}).then(function(response) {

					if (response.key) {
						
						Vue.set(context.state, 'session', response.data)

					}

					resolve(response)

				})

			})

		},

		share: function(context, recipient) {

			return new Promise(function(resolve) {

				api.post('share', {
					recipient: recipient
				}).then(function(response) {

					resolve(response)

				})

			})

		}

	}

})
