<template>

<div class="content" :class="{'is-light': isLight}">

	<div class="content-inner">

		<slot></slot>
	
	</div>

</div>

</template>

<script>

export default {

	props: ['isLight']
	
}

</script>

<style scoped>

.content {
	width: 100%;
}

.content-inner {
	max-width: 960px;
	width: 100%;
	margin: 0px auto;
	padding: 40px 0px;
}

.is-mobile .content-inner {
	padding: 20px 0px;
}

.content >>> h2 {
	text-transform: uppercase;
	font-size: 36px; 
	line-height: 36px;
	color: #312D50;
	letter-spacing: 3.6px;
	font-weight: 300;
	margin-bottom: 40px;
}

.is-mobile .content >>> h2 {
	font-size: 30px;
	margin-bottom: 20px;
}

.content >>> p {
	font-size: 18px;
	line-height: 22px;
	color: #343434;
	font-weight: 300;
	margin-bottom: 20px;
}

.content.is-light >>> p {
	color: #fff;
}

.is-mobile .content >>> p {
	margin-bottom: 20px;
}

.is-mobile .content >>> img {
	max-width: 100%;
}

.is-mobile .content >>> img {
	max-width: 100%;
	float: none!important;
	margin-right: auto!important;
	margin: 0px auto 20px auto;
	display: block;
}

.content >>> p:last-child {
	margin-bottom: 0px;
}

</style>
