<template>

<com-screen id="app" :loading="is.loading" :class="{'is-mobile': window.is.mobile, 'is-tablet': window.is.tablet}">

	<router-view></router-view>

</com-screen>

</template>

<script>

import comScreen from '@/components/Screen'

export default {

	name: 'App',

	components: {
		'com-screen': comScreen
	},

	data: function() {

		return {
			is: {
				initial: true,
				loading: true
			}
		}

	},

	computed: {

		route: function() {

			return this.$route.name

		}

	},

	watch: {

		route: async function(n) {

			if (this.is.initial) {

				this.is.initial = false

				await this.$store.dispatch('init', this.$route.params.key)

				this.is.loading = false

				if (n === 'Profile' && this.$store.getters['recommendation']) {

					this.$router.push({
						name: 'Recommendation'
					})

				} else if (!this.$_.contains(['Home', 'Generic'], n)) {

					this.$router.push({
						name: 'Home'
					})

				}

			} else {

				window.scrollTo(0, 0)

			}

		}

	}

}

</script>

<style>

#app {
	min-height: 100vh;
	min-height: calc(100vh - var(--vh-offset, 0px));
}

</style>
